import gatewayService from '@/modules/common/services/gatewayService';

const baseRoute = '/business/subscriptionsmng/v1';

const createSubscription = async (params) => gatewayService.post(`${baseRoute}/subscriptions`, params).then((res) => res.data).catch((error) => {
  console.error(error);
  return { code: 'error' };
});

const purchaseItemSubscription = async (itemUid, amount) => gatewayService.post(`${baseRoute}/subscriptions/items/${itemUid}`, { item: { amount } }).then((res) => res.data).catch((error) => {
  console.error(error);
  return { code: 'error' };
});

const cancelSubscription = async (subscriptionUid) => gatewayService.put(`${baseRoute}/subscriptions/${subscriptionUid}`, {
  subscription: {
    purchase_state: 'canceled',
  },
});

const getAccount = async () => gatewayService.get(`${baseRoute}/accounts`).then((res) => res.data).catch((error) => {
  console.error(error);
  return { code: 'error' };
});
const getSubscriptionDetails = async () => {
  let result;
  try {
    const accountDetails = await getAccount();
    result = await gatewayService.get(`${baseRoute}/subscriptions?[filter][uid]=${accountDetails.main_subscription_uid}`).then((res) => res.data.subscriptions[0]);
    const purchasables = await gatewayService.get(`${baseRoute}/purchasables?page=1&per_page=50&filter[purchasable_type]=package&filter[uid]=${result.purchasable_uid}`);
    const purchasable = purchasables.data.purchasables[0];
    result.purchasable_item_uid = purchasable.item_uid;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

export default {
  createSubscription,
  purchaseItemSubscription,
  cancelSubscription,
  getSubscriptionDetails,
};
