const routes = [
  {
    path: '/embedded/:appName',
    name: 'embeddedApp',
    component: () => import(/* webpackChunkName: "EmbeddedApp" */ './views/LiveEmbeddedAppsPage.vue'),
    props: (route) => ({
      featureFlag: '',
      angularRoute: '',
      query: route.query,
      ...route.params,
    }),
    children: [{
      path: ':route',
      name: 'embeddedAppRoute',
      component: () => import(/* webpackChunkName: "EmbeddedApp" */ './views/LiveEmbeddedAppsPage.vue'),
      props: (route) => ({
        featureFlag: '',
        angularRoute: '',
        query: route.query,
        ...route.params,
      }),
    }],
  },
];

export default routes;
