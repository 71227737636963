import taxesService from '@/modules/payments/services/taxesService';

const state = {
  taxes: [],
};

const getters = {
  taxes: (state) => state.taxes,
};

const mutations = {
  setTaxes(state, taxes) {
    state.taxes = taxes;
  },
};

const actions = {
  async loadTaxes({ commit }) {
    let hasMore = true;
    let page = 1;
    const taxesArray = [];
    /* eslint-disable no-await-in-loop */
    while (hasMore) {
      const taxes = await taxesService.taxList({ page, per_page: 25 });
      Array.prototype.push.apply(taxesArray, taxes.data.data.taxes);
      if (taxes.data.data.taxes.length === 25) {
        hasMore = true;
        page += 1;
      } else {
        hasMore = false;
      }
    }
    /* eslint-disable no-await-in-loop */
    commit('setTaxes', taxesArray);
    return taxesArray;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
