<template>
  <VcLayout :data-qa="dataQa" fill-height align-start column>
    <VcPageHeader v-if="displayHeader" :title="appName" class="embeddedAppHeader"/>
    <iframe class="app-iframe"
            :id="`${appId}-app-iframe`"
            :src="appUrl"
            :data-qa="`${appId}-app-iframe`"
            :title="`${appId}-app-iframe`"></iframe>
  </VcLayout>
</template>

<script>

export default {
  name: 'EmbeddedAppDelegator',
  components: {},
  props: {
    appId: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    appUrl: {
      type: String,
      required: true,
    },
    dataQa: {
      type: String,
      default: 'EmbeddedAppDelegator',
    },
  },
};
</script>

<style lang="scss" scoped>

.embeddedAppHeader.VcPageHeader {
  margin-bottom: var(--size-value4);
}

.app-iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  border: unset;
  display: block;
  //position: absolute;
}

</style>
