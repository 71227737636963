import subscriptionService from '@/modules/subscription/services/subscriptionService';

const state = {
  subscription: undefined,
};

const mutations = {
  setSubscription(state, value) {
    state.subscription = value;
  },
};

const actions = {
  async fetchSubscription({ commit, state }) {
    if (!state.subscription) {
      const subscriptionValue = await subscriptionService.getSubscriptionDetails();
      commit('setSubscription', subscriptionValue);
    }
  },
  async purchaseItemSubscription(_, params) {
    const res = await subscriptionService.purchaseItemSubscription(params.item_uid, params.amount);
    if (res.code === 'error') {
      throw res.error;
    }
  },
  async purchaseSubscription(_, params) {
    const res = await subscriptionService.createSubscription(params.purchasable_data);
    if (res.error) {
      throw res.error;
    }
  },
};

const getters = {
  subscription: (state) => state.subscription,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
