import AiPlatformService from '@/modules/aiContentGenerator/services/aiPlatformService';
import httpService from '@/modules/common/services/httpService';
import fetchAdapter from '@/modules/aiContentGenerator/utils/axiosFetchAdapter';
import StreamProcessor from '@/modules/aiContentGenerator/utils/streamProcessor';
import {
  AiErrorFactory,
  AiOperations,
} from '@/modules/aiContentGenerator/utils/aiErrors';

const aiPlatformService = new AiPlatformService(
  httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY),
  httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY, undefined, {
    adapter: fetchAdapter,
  }),
);

const state = {
  textValues: {},
  promptValues: {},
  chatUids: {},
  errorValues: {},
  isStreaming: {},
};

const getters = {
  isStreaming: (state) => (feature) => state.isStreaming[feature],
  textValue: (state) => (feature) => state.textValues[feature],
  promptValue: (state) => (feature) => state.promptValues[feature],
  chatUid: (state) => (feature) => state.chatUids[feature],
  errorValue: (state) => (feature) => state.errorValues[feature],
};

const mutations = {
  setIsStreaming(state, { feature, value }) {
    state.isStreaming = { ...state.isStreaming, [feature]: value };
  },
  setTextValue(state, { feature, value }) {
    const cur = state.textValues[feature] ?? '';
    state.textValues = { ...state.textValues, [feature]: cur + value };
  },
  clearTextValue(state, feature) {
    state.textValues[feature] = '';
  },
  setPromptValue(state, { feature, value }) {
    state.promptValues = { ...state.promptValues, [feature]: value };
  },
  setChatUid(state, { feature, value }) {
    state.chatUids = { ...state.chatUids, [feature]: value };
  },
  setErrorValue(state, { feature, value }) {
    state.errorValues = { ...state.errorValues, [feature]: value };
  },
  clearOperationErrors(state, { feature, operationError }) {
    if (AiOperations.is(state.errorValues[feature], operationError)) {
      state.errorValues[feature] = null;
    }
  },
};

const actions = {
  async createChat({ commit }, {
    feature, agentName, instruction, windowSize,
  }) {
    commit('clearOperationErrors', { feature, operationError: AiOperations.createChat });
    try {
      const response = await aiPlatformService.createChat(agentName, { instruction, windowSize });
      commit('setChatUid', { feature, value: response.data.data.uid });
    } catch (error) {
      commit('setErrorValue', { feature, value: AiErrorFactory(error, AiOperations.createChat) });
    }
  },
  // eslint-disable-next-line no-shadow
  async sendMessage({ commit, getters }, {
    feature, content, streaming = true,
  }) {
    const chatUid = getters.chatUid(feature);
    commit('clearOperationErrors', { feature, operationError: AiOperations.stream });
    try {
      commit('clearTextValue', feature);
      commit('setIsStreaming', { feature, value: true });

      const response = await aiPlatformService.sendMessage(chatUid, content, streaming);
      await StreamProcessor.processStream(response, (data) => {
        if (data.finish_reason !== 'stop') {
          commit('setTextValue', { feature, value: data.delta });
        }
      });
    } catch (error) {
      commit('setErrorValue', { feature, value: AiErrorFactory(error, AiOperations.stream) });
    } finally {
      commit('setIsStreaming', { feature, value: false });
    }
  },
  async getPrompts({ commit, state }, feature) {
    commit('clearOperationErrors', { feature, operationError: AiOperations.getPrompts });
    if (state.promptValues[feature]) return;
    try {
      const response = await aiPlatformService.getPrompts(feature);
      commit('setPromptValue', { feature, value: response.data.data });
    } catch (error) {
      commit('setErrorValue', { feature, value: AiErrorFactory(error, AiOperations.getPrompts) });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
