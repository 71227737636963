import callsTextingService from '@/modules/callsTexting/services/callsTextingService';

const state = {
  callsSettings: null,
  quotaBalance: null,
  quotaCredit: null,
  activeDedicatedNumber: null,
  showBusinessInfoError: false,
};

const getters = {
  callsSettings: (state) => state.callsSettings,
  quotaCredit: (state) => state.quotaCredit,
  quotaBalance: (state) => state.quotaBalance,
  activeDedicatedNumber: (state) => state.activeDedicatedNumber,
  showBusinessInfoError: (state) => state.showBusinessInfoError,
};

const mutations = {
  setShowBusinessInfoError(state, show) {
    state.showBusinessInfoError = show;
  },
  setCallsSettings(state, settings) {
    state.callsSettings = settings;
  },
  setCallQuota(state, { credit, balance }) {
    state.quotaCredit = credit;
    state.quotaBalance = balance;
  },
  setActiveDedicatedNumber(state, activeDedicatedNumber) {
    state.activeDedicatedNumber = activeDedicatedNumber;
  },
};

const actions = {
  setShowBusinessInfoError({ commit }, show) {
    commit('setShowBusinessInfoError', show);
  },
  async createCallsSettings({ commit }, settings) {
    try {
      const result = await callsTextingService.createCallsSettings(settings);
      commit('setCallsSettings', result.data.settings);
      return result;
    } catch (error) {
      return { code: 'error', message: error.message };
    }
  },
  async updateCallsSettings({ commit }, settings) {
    const result = await callsTextingService.updateCallsSettings(settings);
    commit('setCallsSettings', result.data.settings);
  },
  async fetchCallsSettings({ commit }) {
    const result = await callsTextingService.getCallsSettings();
    if (result.success) {
      commit('setCallsSettings', result.data.settings);
    }
  },
  async getRemainingQuota({ commit }) {
    const result = await callsTextingService.getRemainingQuota();
    if (result.data.quota) {
      commit('setCallQuota', result.data.quota);
    }
  },
  async fetchActiveDedicatedNumber({ commit }, params) {
    try {
      const activeDedicatedNumber = await callsTextingService.getBusinessActiveDedicatedNumber(params);
      commit('setActiveDedicatedNumber', activeDedicatedNumber);
      return activeDedicatedNumber;
    } catch (err) {
      return err;
    }
  },
  async setTextingStatus({ commit, state }, status) {
    await callsTextingService.toggleTextingStatus(status);
    const { activeDedicatedNumber } = state;
    commit('setActiveDedicatedNumber', { ...activeDedicatedNumber, status });
  },
  async claimNumber({ commit }, { voiceTextAppInstalled }) {
    try {
      const activeDedicatedNumber = await callsTextingService.claimNumber({ voiceTextAppInstalled });
      commit('setActiveDedicatedNumber', activeDedicatedNumber);
      return activeDedicatedNumber;
    } catch (err) {
      return err;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
