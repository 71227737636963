import Vue from 'vue';
import Vuex from 'vuex';
import AuthStore from '@/modules/auth/store/authStore';
import CommonStore from '@/modules/common/store/commonStore';
import AngularStore from '@/modules/angularDelegator/store/angularStore';
import ExternalScriptsStore from '@/modules/externalScripts/store/externalScriptsStore';
import LayoutTopBarStore from '@/modules/layout/topBar/store/layoutTopBarStore';
import BusinessStore from '@/modules/business/store/businessStore';
import BusinessSettingsStore from '@/modules/business/store/businessSettingsStore';
import StaffStore from '@/modules/staff/store/staffStore';
import MainMenuStore from '@/modules/layout/mainMenu/store/mainMenuStore';
import LayoutBottomNavStore from '@/modules/layout/bottomNav/store/layoutBottomNavStore';
import ToastStore from '@/modules/layout/toast/store/toastStore';
import ConversationStore from '@/modules/conversation/store/conversationStore';
import LayoutStore from '@/modules/layout/store/layoutStore';
import SidePaneStore from '@/modules/sidePane/store/sidePaneStore';
import PaymentsStore from '@/modules/payments/store/paymentsStore';
import PaymentRequestStore from '@/modules/paymentRequest/store/paymentRequestStore';
import PaymentSettingsStore from '@/modules/paymentSettings/store/paymentSettingsStore';
import TaxesStore from '@/modules/payments/store/taxesStore';
import MatterStore from '@/modules/matter/store/matterStore';
import ClientStore from '@/modules/client/store/clientStore';
import SystemNotificationStore
  from '@/modules/layout/systemNotifications/store/systemNotificationStore';
import NotificationCenterStore from '@/modules/notificationCenter/store/notificationCenterStore';
import MobileBottomSheetStore
  from '@/modules/layout/mobileBottomSheet/store/mobileBottomSheetStore';
import QuickActionsStore from '@/modules/layout/quickActions/store/quickActionsStore';
import SearchBarStore from '@/modules/layout/topBar/store/SearchBarStore';
import ModalStore from '@/modules/modals/store/modalStore';
import appMarketStore from '@/modules/appMarket/store/appMarketStore';
import BizaiStore from '@/modules/bizai/store/bizaiStore';
import AppsStore from '@/modules/app/store/appsStore';
import ViewStore from '@/modules/crm/store/viewStore';
import AiGeneratorStore from '@/modules/aiContentGenerator/store/aiGeneratorStore';
import PosStore from '@/modules/payments/store/posStore';
import ClientPackageStore from '@/modules/clientPackage/store/clientPackageStore';
import ProductOrderStore from '@/modules/productOrder/store/productOrderStore';
import EventAttendanceStore from '@/modules/eventAttendance/store/eventAttendanceStore';
import AppointmentStore from '@/modules/appointment/store/appointmentStore';
import ServicesStore from '@/modules/services/store/servicesStore';
import ProductsStore from '@/modules/products/store/productsStore';
import CalendarSyncStore from '@/modules/wizards/TwoWaySyncWizard/store/calendarSyncStore';
import SaleStore from '@/modules/payments/store/saleStore';
import CallsTextingStore from '@/modules/callsTexting/store/callsTextingStore';
import SubscriptionStore from '@/modules/subscription/store/subscriptionStore';
import AppStore from '@/modules/app/store/appStore';
import BillingStore from '@/modules/billing/store/billingStore';
import AccountStore from '@/modules/account/store/accountStore';
import PurchasableStore from '@/modules/purchasable/store/purchasableStore';
import ReserveWithGoogleStore from '@/modules/reserveWithGoogle/store/reserveWithGoogleStore';
import CategoriesStore from '@/modules/categories/store/categoriesStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isInitialDataLoaded: false,
  },
  getters: {
    isInitialDataLoaded: (state) => state.isInitialDataLoaded,
  },
  mutations: {
    setIsInitialDataLoaded(state, isInitialDataLoaded) {
      state.isInitialDataLoaded = isInitialDataLoaded;
    },
  },
  actions: {
    setIsInitialDataLoaded({ commit }, isInitialDataLoaded) {
      commit('setIsInitialDataLoaded', isInitialDataLoaded);
    },
  },
  modules: {
    AiGeneratorStore,
    AuthStore,
    CommonStore,
    AngularStore,
    ExternalScriptsStore,
    LayoutTopBarStore,
    BusinessStore,
    BusinessSettingsStore,
    StaffStore,
    MainMenuStore,
    LayoutBottomNavStore,
    ToastStore,
    ConversationStore,
    LayoutStore,
    SidePaneStore,
    PaymentsStore,
    PaymentRequestStore,
    PaymentSettingsStore,
    TaxesStore,
    MatterStore,
    ClientStore,
    SystemNotificationStore,
    NotificationCenterStore,
    MobileBottomSheetStore,
    QuickActionsStore,
    SearchBarStore,
    appMarketStore,
    ModalStore,
    BizaiStore,
    AppsStore,
    ViewStore,
    PosStore,
    ClientPackageStore,
    ProductOrderStore,
    EventAttendanceStore,
    AppointmentStore,
    ServicesStore,
    ProductsStore,
    CalendarSyncStore,
    SaleStore,
    CallsTextingStore,
    SubscriptionStore,
    AppStore,
    BillingStore,
    AccountStore,
    PurchasableStore,
    ReserveWithGoogleStore,
    CategoriesStore,
  },
});
