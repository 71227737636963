const routes = [
  {
    path: '/payment-settings',
    component: () => import(/* webpackChunkName: "PaymentSettings" */ './views/PaymentSettingsPage.vue'),
    name: 'payment-settings',
    props: { featureFlag: 'payment_settings_pov', angularRoute: 'app.settings_page', angularRouteParams: { page: 'payments' } },
  },
];

export default routes;
